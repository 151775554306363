import type { TStoryblock } from '@hubcms/domain-storyblock';
import { TeaserPropsOptions, isTeaserList } from '@hubcms/domain-teaser';
import { AbstractGroup, SectionParams, TeaserFragment } from '@hubcms/domain-cook';
import { isNonNull } from '@hubcms/utils-browser';

import { createTeaserAreas } from './createTeaserAreas';
import { mapToGroupOptionsRecord } from './mapToGroupOptionsRecord';
import { mapStoryblock } from './mapStoryblock';
import { getStoryblockOptions } from './getStoryblockOptions';

type UseStoryblockOptions = {
  areaNames?: string[];
  hasNoAds?: boolean;
  sectionParams: SectionParams;
  teaserPropsOptions: TeaserPropsOptions;
};

export function getStoryblocks(
  rootGroup: (AbstractGroup & { [key: string]: AbstractGroup[] | TeaserFragment[] }) | undefined,
  { areaNames = rootGroup?.areaNames || [], hasNoAds = false, sectionParams, teaserPropsOptions }: UseStoryblockOptions,
): TStoryblock[] {
  if (!rootGroup) {
    return [];
  }

  const createStoryblockFromGroup = storyblockCreator(teaserPropsOptions, {
    sectionParams,
    hasNoAds,
  });

  const storyblocks: TStoryblock[] = areaNames
    .flatMap(areaName => {
      if (areaName in rootGroup && isNonNull(rootGroup[areaName])) {
        const area = rootGroup[areaName];
        if (isAreaWithoutGroups(area)) {
          const storyblock = createStoryblockFromGroup(rootGroup, 0, { name: areaName, isExclusive: true });
          return [storyblock];
        }
        return area
          .filter(
            ({ groupOptions }) => groupOptions && mapToGroupOptionsRecord(groupOptions).webStoryblock?.toLowerCase() !== 'hide',
          )
          .map((group, idx) => {
            return createStoryblockFromGroup(group, idx, { name: areaName });
          });
      }
      return [];
    })
    .filter(filterEmptyStoryblocks);

  return storyblocks;
}

function isAreaWithoutGroups(area: AbstractGroup[] | TeaserFragment[]): area is TeaserFragment[] {
  return !!area.length && !('groupOptions' in area[0]);
}

type StoryblockCreatorOptions = {
  sectionParams: SectionParams;
  hasNoAds: boolean;
};
function storyblockCreator(teaserPropsOptions: TeaserPropsOptions, { sectionParams, hasNoAds }: StoryblockCreatorOptions) {
  return function createStoryblockFromGroup(
    group: AbstractGroup,
    idx: number,
    area: {
      name: string;
      isExclusive?: boolean;
    },
  ): TStoryblock {
    const storyblockOptions = getStoryblockOptions({ group, sectionParams, canHaveAds: !hasNoAds });
    const groupTitle = group.groupOptions.find(({ key }) => key === 'groupTitle')?.value?.replace(/ /g, '-');

    return {
      areaName: area.name,
      blockName: `${group.type}_${idx}`,
      storyblockOptions,
      theme: getTheme(group),
      unflattenedTeaserAreas: createTeaserAreas(group, teaserPropsOptions, area.isExclusive ? area.name : undefined),
      groupId: [area.name, group.__typename, groupTitle, idx].filter(item => !!item).join('-'),
      createGridData: (teaserAreas, overrideStoryblockOptions) =>
        mapStoryblock({ ...storyblockOptions, ...overrideStoryblockOptions }, teaserAreas),
    };
  };
}

function filterEmptyStoryblocks(storyblock: TStoryblock): boolean {
  return Object.values(storyblock.unflattenedTeaserAreas).some(area => {
    // check for teasers
    return (
      area.length > 0 &&
      area.some(teaser => !isTeaserList(teaser) || teaser.listType !== 'article-list' || teaser.items.length > 0)
    );
  });
}

function getTheme(group: AbstractGroup): string {
  const groupOptions = mapToGroupOptionsRecord(group.groupOptions);

  return groupOptions.groupTheme || 'none';
}
